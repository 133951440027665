import api from "./apiInstance";

const getTitle = () => localStorage.getItem("eventTitle");
const getPhoto = () => localStorage.getItem("eventPhoto");
const getDescription = () => localStorage.getItem("eventDescription");
const getThankYouMessage = () => localStorage.getItem("eventThankYou");
const saveData = (data) => {
    localStorage.setItem("eventTitle", data.title);
    localStorage.setItem(
      "eventPhoto",
      data.mainImage
    );
    localStorage.setItem("eventDescription", data.description);
    localStorage.setItem("eventThankYou", data.thankYouMessage);
  };
const eventData = {
  title: () => getTitle(),
  photo: () => getPhoto(),
  description:() => getDescription(),
  thankYouMessage:() => getThankYouMessage(),
  saveData: saveData,
  isInitialized: () => getTitle() && getPhoto() && getDescription() && getThankYouMessage() && true,
  init: (id) => {
    return api
      .getIntance()
      .get(`/api/event/${id}/`)
      .then((response) => {
        saveData(response.data);
      });
  },
  flush: () => {
    localStorage.removeItem("eventTitle");
    localStorage.removeItem("eventPhoto");
    localStorage.removeItem("eventDescription");
    localStorage.removeItem("eventThankYou");
  },
};

export default eventData;
