import React from 'react';
import { Container } from '@mui/material';
import { Button } from '@mui/base';
import './partials.scss';
import QrCode from '../../components/QrCode/QrCode';
import ModalDialog from '../../components/Dialog/Dialog';
import QrCode2Icon from '@mui/icons-material/QrCode2';

const FixedBottomButtons = (props) => {

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getQRUrl = () => {
    const currentUrl = window.location.href;
    const lastSlashIndex = currentUrl.lastIndexOf('/');
    const url = currentUrl.substring(0, lastSlashIndex + 1) + 'main';
    
    return url;
  };

  const qr = (
    <QrCode
      value={getQRUrl()}
    />
  );
  return (
    <div className="buttons-footer">
      <ModalDialog
        dialogTitle="Podziel się tym wydarzeniem z innymi!"
        dialogContent={qr}
        onOpen={openDialog}
        onClose={handleClose}
      />
      <Container maxWidth="sm" className="App">
        <div className="buttons-wrapper">
          <Button
            variant="contained"
            size="small"
            className="primary btn-small"
            href={'/' + props.id + '/' + props.type + ''}
          >
            {props.text}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="secondary--filled btn-small icon"
            onClick={handleOpen}
          >
            <QrCode2Icon/>
          </Button>
          <Button
            variant="contained"
            size="small"
            className="primary btn-small"
            href={'/' + props.id + '/main'}
          >
            Idź do głownej
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default FixedBottomButtons;