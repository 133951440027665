import React from "react";
import QRCode from "qrcode.react";
import './qrcode.scss';

function QrCode({ value }) {
  return (
    <div>
      <QRCode value={value} className="qrcode" />
    </div>
  );
}

export default QrCode;
