import React, { useState, useRef, useEffect } from 'react';

const ShowMoreText = ({ text, lines }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef(null);
  const [shouldShowButton, setShouldShowButton] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = 16;
      const height = parseFloat(lineHeight) * lines;
      console.log(textRef.current.scrollHeigh, height);
      setShouldShowButton(textRef.current.scrollHeight > height);
    }
  }, [text, lines]);

  return (
    <div>
      <div
        style={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'unset' : lines,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          lineHeight: '16px',
        }}
        ref={textRef}
      >
        {text}
      </div>
      {shouldShowButton && !isExpanded && (
        <button onClick={toggleExpand} style={{ marginTop: '10px' }} className="btn-link">
          Pokaż więcej
        </button>
      )}
      {isExpanded && (
        <button onClick={toggleExpand} style={{ marginTop: '10px' }} className="btn-link">
          Pokaż mniej
        </button>
      )}
    </div>
  );
};

export default ShowMoreText;
