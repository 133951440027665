import React from "react";
import QrCode from "../../components/QrCode/QrCode";
import { useParams } from "react-router-dom";
import { Button } from "@mui/base";

const QR = () => {
  const {id} = useParams();
  const getUrl = () => {
    const currentUrl = window.location.href;
    const lastSlashIndex = currentUrl.lastIndexOf("/");
    const baseUrl = currentUrl.substring(0, lastSlashIndex + 1);
    //console.log("baseUrl", baseUrl);
    return baseUrl;
  };

  return (
    <div>
      <h1>QR</h1>
      <QrCode value={`${getUrl()}main`} logo="https://www.tailorbrands.com/wp-content/uploads/2020/07/mcdonalds-logo.jpg" />
      <div className="form-field">
          <Button
            variant="contained"
            size="large"
            className="secondary btn-large"
            href={'/' + id + '/main'}
          >
            Wróć do głownej
          </Button>
        </div>
    </div>
  );
};

export default QR;
