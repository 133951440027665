import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router";
import api from "../../utlis/apiInstance";
import LazyLoadComponent from "react-lazyload-v18";
import { CircularProgress, Container } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./PhotoList.scss";
import { height, width } from "@mui/system";
import ShowMoreText from "../Partials/ShowMore";
import Config from "./../../utlis/config";

const PhotoList = () => {
  const [photos, setPhotos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [expanded, setExpanded] = useState();
  const { id } = useParams();
  const [nextToken, setNextToken] = useState("");
  const scrollableDivRef = useRef(null);
  const maxHeight = 300;
  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    if (fetching) {
      return;
    }
    setFetching(true);

    api
      .getIntance()
      .get(
        `/api/event/${id}/photos/?pagesize=15&width=${scrollableDivRef.current.offsetWidth}&token=${nextToken}`
      )
      .then((response) => {
        console.log("initial scrool photos", response);
        if (response.status === 200) {
          const newPhotos = response.data.photos;
          setPhotos([...photos, ...newPhotos]);

          setNextToken(response.data.nextPageToken);
          setFetching(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching photos:", error);
        setFetching(false);
      });
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    //console.log(scrollTop, scrollHeight, clientHeight);

    if (scrollHeight - scrollTop <= clientHeight + 2) {
      event.currentTarget.scrollTop = scrollTop - 4;
      if (nextToken) {
        fetch();
      }
    }
  };

  const loader = (
    <div className="overlay">
      <CircularProgress />
    </div>
  );
  const loadMore = () => {
    fetch();
  };

  const handleExpandClick = (item) => {
    setExpanded(item);
  };

  const handleCollapseClick = (item) => {
    setExpanded(null);
  };

  return (
    <div
      style={{
        height: `100%`,
        overflowY: 'auto' /* Hide vertical scrollbar */,
        overflowX: 'hide' /* Enable horizontal scrollbar if needed */,
        width: `100%`,
      }}
      className="photo-list"
      onScroll={handleScroll}
    >
      {fetching && (
        <div className="overlay">
          <CircularProgress />
        </div>
      )}
      <InfiniteScroll
        dataLength={photos.length}
        next={loadMore}
        hasMore={hasMore}
        // loader={loader}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          width: '100%',
        }}
        className="gallery"
      >
        <Container
          ref={scrollableDivRef}
          maxWidth="sm"
          className="container-wrapper"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {photos.map((photo, index) => (
            <div className="post" key={photo.id}>
              {/* <div
                className={`photo-wrapper ${
                  photo.id === expanded
                    ? "expanded"
                    : photo.height >= maxHeight
                    ? "collapsed"
                    : ""
                }`} */}
              <div
                className={`photo-wrapper`}
                style={{ height: `${scrollableDivRef.current.offsetWidth}px` }}
                // style={{ height: `${photo.height}px` }}
              >
                <LazyLoadImage
                  src={Config.apiEndpoint + photo.thumbnail}
                  alt={photo.name}
                  key={`photo-${photo.id}`}
                  effect="blur"
                  // width={300} 
                />
                {/* <img
                  src={photo.thumbnail}
                  key={`photo-${photo.id}`}
                  alt={photo.name}
                /> */}
              </div>
              <div className="wish">
                <h2>{photo.name}</h2>
                <ShowMoreText
                  key={photo.id}
                  text={photo.description}
                  lines={2}
                />
                <p className="date">{new Date(photo.timestamp).toLocaleString()}</p>
              </div>
            </div>
          ))}
          {nextToken && (
            <div>
              <p>Wczytaj więcej</p>
            </div>
          )}
        </Container>
      </InfiniteScroll>
    </div>
  );
};

export default PhotoList;
