import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { AuthContext } from "./AuthContext";
import { AuthContext } from '../AuthContext/AuthContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Config from '../../utlis/config';
import { Button } from '@mui/base';
import '../../assets/styles/_form.scss';

const Login = () => {
  const { id } = useParams();
  const { isAuthenticated, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogout = (e) => {
    logout();
  };

  const handleLogin = (e) => {
    e.preventDefault();

    axios
      .post(`${Config.apiEndpoint}/api/event/auth/${id}`, {
        password: password,
      })
      .then((response) => {
        // Assuming the server returns a response with the JWT token
        const token = response.data.token;

        // Call the login function from the AuthContext to set the authentication status
        login(token);

        // Redirect the user to the desired page after successful login
        navigate(`/${id}/main`);
      })
      .catch((error) => {
        // Handle authentication error, display error message, etc.
        console.log(error);
        setError('Wydarzenie lub hasło jest nieprawidłowe');
      });
  };

  return isAuthenticated ? (
    <div className="login site">
      <form onSubmit={handleLogout}>
        <Button
          variant="contained"
          size="large"
          className="primary btn-large"
          type="submit"
        >
          WYLOGUJ
        </Button>
      </form>
    </div>
  ) : (
    <div className="login site">
      <form onSubmit={handleLogin}>
        <div className="form-field">
          <label>
            {/* <h2>Hasło dostępu</h2> */}
            <input
              type="password"
              value={password}
              placeholder="HASŁO"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        {error && <div className="form-field">{error}</div>}
        <div className="form-field">
          <Button
            variant="contained"
            size="large"
            className="secondary btn-large"
            type="submit"
          >
            ZALOGUJ
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
