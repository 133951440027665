import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./photos.scss";
import FixedBottomButtons from "../../components/Partials/FixedBottomButtons";
import PhotoList from "../../components/PhotoList/PhotoList";
import eventData from "../../utlis/eventData";
import FixedTopCouple from "../../components/Partials/FixedTopCouple";

const Photos = () => {
  const { id } = useParams();
  const [image, setImage] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setImage(eventData.photo);
  };

  return (
    <div className="photos">
      <FixedTopCouple image={image} size="small" />
      <div className="photos-content">
        <PhotoList />
      </div>
      <FixedBottomButtons id={id} type="upload" text="wrzuć zdjęcie" />
    </div>
  );
};

export default Photos;
