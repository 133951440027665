import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import './dialog.scss';

function ModalDialog({dialogTitle, dialogContent, onOpen, onClose}){
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={onOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      className="modal"
    >
        <IconButton
          aria-label="close"
          className="dialog-close functional"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 30,
            top: 30,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon />
        </IconButton>
      {dialogContent && (
        <DialogContent className="dialog-content">
          <h2>{dialogTitle}</h2>
          {dialogContent}
        </DialogContent>
      )}
    </Dialog>
  );
}

export default ModalDialog;
