import React, { useContext, useEffect, useState } from "react";
import "./home.scss";
import "../../assets/styles/_buttons.scss";
import { Button, CircularProgress, Container } from "@mui/material";
import QrCode from "../../components/QrCode/QrCode";
import ModalDialog from "../../components/Dialog/Dialog";
import { useParams } from "react-router-dom";
import api from "../../utlis/apiInstance";
import { AuthContext } from "../../components/AuthContext/AuthContext";
import AlertDialog from "../../components/Alert/Alert";
import eventData from "../../utlis/eventData";
import FixedTopCouple from "../../components/Partials/FixedTopCouple";

const Home = ({ username }) => {
  const { isAuthenticated, login, logout } = useContext(AuthContext);

  const { id } = useParams();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isError, setIsError] = useState(false);
  const [apiError, setApiError] = useState({});
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    console.log("eventData", eventData, eventData.isInitialized());
    if (eventData.isInitialized()) {
      setTitle(eventData.title);
      setDescription(eventData.description);
      setImage(eventData.photo);
    } else {
      setFetching(true);
      eventData
        .init(id)
        .then(() => {
          setTitle(eventData.title);
          setDescription(eventData.description);
          setImage(eventData.photo);
          setFetching(false);
        })
        .catch((error) => {
          setApiError(error);
          setIsError(true);
          setFetching(false);
        });

    }
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getQRUrl = () => {
    const currentUrl = window.location.href;
    const lastSlashIndex = currentUrl.lastIndexOf("/");
    const url = currentUrl.substring(0, lastSlashIndex + 1) + 'main';
    return url;
  };

  const qr = (
    <QrCode
      value={getQRUrl()}
    />
  );

  return (
    <div className="home site">
      <Container maxWidth="sm" className="container-wrapper">
        {fetching && (
          <div className="overlay">
            <CircularProgress />
          </div>
        )}
        <FixedTopCouple image={image} size="big" />
        <div className="welcome-wrapper">
          <ModalDialog
            dialogTitle="Podziel się tym wydarzeniem z innymi!"
            dialogContent={qr}
            onOpen={openDialog}
            onClose={handleClose}
          />
          <h1>
            {/* Witaj <span>{username}</span> <span>{id}</span>! */}
            {title}
          </h1>
          <h2>{description}</h2>
          {isError && <AlertDialog error={apiError} type="error" />}
        </div>
        <div className="button-wrapper">
          <Button
            variant="contained"
            size="large"
            className="primary btn-large"
            href={'/' + id + '/upload'}
          >
            Wrzuć zdjęcie
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary btn-large"
            href={'/' + id + '/photos'}
          >
            Zobacz galerię
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary btn-large"
            onClick={handleOpen}
          >
            Pokaz kod QR
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary btn-large"
            onClick={logout}
          >
            Wyloguj
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Home;
