import React, { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AuthContext } from "../AuthContext/AuthContext";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const {id} = useParams();
    const { isAuthenticated } = useContext(AuthContext);
    const url = `/${id}/login`;

    return isAuthenticated ? <Component/> : <Navigate to={url} replace={true} />
  };

export default ProtectedRoute;
