import axios from "axios";
import Config from "./config";

const api = {
    getIntance: () => axios.create({
        baseURL: Config.apiEndpoint,
        timeout: 180000,
        headers: {'Authorization': 'Bearer '+ localStorage.getItem("token")}
    })
};
export default api;