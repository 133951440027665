import { Button, CircularProgress, Container } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useParams } from 'react-router-dom';
import '../../assets/styles/_buttons.scss';
import '../../assets/styles/_form.scss';
import AlertDialog from '../../components/Alert/Alert';
import FixedBottomButtons from '../../components/Partials/FixedBottomButtons';
import FixedTopCouple from '../../components/Partials/FixedTopCouple';
import api from '../../utlis/apiInstance';
import getCroppedImg from '../../utlis/cropImage';
import eventData from '../../utlis/eventData';
import './upload.scss';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

const Upload = () => {
  const { id } = useParams();
  const inputRef = useRef(null);

  const [file, setFile] = useState([]);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [image, setImage] = useState();
  const [isSumbitted, setSubmitted] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [apiError, setApiError] = useState({});
  const [thankYou, setThankYou] = useState();
  const [title, setTitle] = useState();
  const [crop2, setCrop2] = useState({ x: 0, y: 0 });
  const [zoom2, setZoom2] = useState(1);
  const [croppedArea, setCroppedArea] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setSubmitted(false);
    handleClose();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //console.log(croppedArea, croppedAreaPixels);
    setCroppedArea(croppedArea);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleFileChange = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setFile(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleLoader = (value) => {
    setIsLoader(value);
  };

  const handleClose = () => {
    //setOpenDialog(false);
    setName('');
    setComment('');
    setFile([]);
    resetFileInput();
    resetCrop();
  };

  const resetFileInput = () => {
    //inputRef.current.value = null;
  };

  const sendDataToServer = () => {
    handleLoader(true);

    getCroppedImg(file, croppedAreaPixels).then((x) => {
      console.log('wtf', x.file);

      const formData = new FormData();
      formData.append('photo', x.file);
      formData.append('name', name);
      if (comment !== '') {
        formData.append('comment', comment);
      }

      api
        .getIntance()
        .post(`/api/event/${id}/photos`, formData)
        .then((response) => {
          //console.log(response.status);
          handleLoader(false);
          setOpenDialog(true);
          setSubmitted(true);
          setIsError(false);
          handleNextStep();
        })
        .catch((error) => {
          //console.log(error);
          setApiError(error);
          setIsError(true);
          handleLoader(false);
        });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setImage(eventData.photo);
    setTitle(eventData.title);
    setThankYou(eventData.thankYouMessage);
  };

  const thanksMessage = (
    <div className="thanks-msg">
      <p>{thankYou}</p>
      <div className="cheers"></div>
      {/* <br />
      <p>{title}</p> */}
    </div>
  );

  const resetCrop = () => {
    setFile([]);
    setCrop2({ x: 0, y: 0 });
    setZoom2(1);
  };

  const cropperContent = (
    <div className="crop-container2">
      <Cropper
        image={file}
        crop={crop2}
        zoom={zoom2}
        aspect={1 / 1}
        onCropChange={setCrop2}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom2}
        minZoom={1}
        maxZoom={2}
        objectFit="vertical-cover"
      />
    </div>
  );

  const step1 = (
    <div>
      {file?.length > 0 && <p>Wykadruj zdjęcie</p>}
      <div className="cropper-wrapper">
        {file?.length > 0 && cropperContent}
        {file.length === 0 && (
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            required
            ref={inputRef}
          />
        )}
      </div>
      {file?.length > 0 && (
        <a href="#" className="btn-link cropper-hyperlink" onClick={resetCrop}>
          Anuluj
        </a>
      )}
    </div>
  );

  const step2 = (
    <div>
      <textarea
        value={comment}
        onChange={handleCommentChange}
        rows="4"
        required
        maxLength={255}
        placeholder="ŻYCZENIA - MAX 255 ZNAKÓW - OPCJONALNE"
      />
      <input
        type="text"
        placeholder="PODPIS"
        value={name}
        onChange={handleNameChange}
        required
      />
    </div>
  );

  const step3 = (
    <Paper square elevation={0} sx={{ p: 3 }}>
      {isSumbitted && (
        <div>
          {thanksMessage}
          <Button
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
            className="primary btn-small"
          >
            Wyślij kolejne zdjęcie
          </Button>
        </div>
      )}
    </Paper>
  );

  const steps = [
    {
      label: 'Wybierz Zdjęcie',
      content: step1,
    },
    {
      label: 'Wpisz Życzenia',
      content: step2,
    },
    {
      label: 'Gotowe',
      content: step3,
    },
  ];

  return (
    <div className="upload site">
      <Container maxWidth="sm" className="container-wrapper">
        {isLoader && (
          <div className="overlay">
            <CircularProgress />
          </div>
        )}
        <FixedTopCouple image={image} size="small" />

        <form>
          {isError && <AlertDialog error={apiError} type="error" />}
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label} className="form-field">
                  <StepLabel>
                    <label>
                      <h2>{step.label}</h2>
                    </label>
                  </StepLabel>
                  <StepContent>
                    <div>{step.content}</div>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        {index === 0 && (
                          <Button
                            variant="contained"
                            className="secondary btn-inline"
                            onClick={handleNextStep}
                            disabled={file.length === 0}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Dalej
                          </Button>
                        )}
                        {index === 1 && (
                          <Button
                            variant="contained"
                            className="secondary btn-inline"
                            onClick={() => {
                              sendDataToServer();
                              //handleNextStep();
                            }}
                            disabled={name === '' || file.length === 0}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Dalej
                          </Button>
                        )}
                        {(index === 0 || index === 1) && (
                          <Button
                            disabled={index === 0}
                            className="primary btn-inline"
                            onClick={handlePrevStep}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Wróć
                          </Button>
                        )}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </form>
      </Container>
      <FixedBottomButtons id={id} type="photos" text="Idź do galerii" />
    </div>
  );
};

export default Upload;
