import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import './alert.scss';


const AlertDialog = (props) => {

  const code = props.error.code;
  const name = props.error.name;
  const msg = props.error.message;
  const response = props.error.response;

  return (
    <Alert className="alert" severity={props.type}>
      <AlertTitle>
        {code && !(code === 'ECONNABORTED' && msg.includes('timeout')) && (
          <ul>
            <li>
              <strong>
                {code} {name} <br />
              </strong>
            </li>
            <li>{msg && msg}</li>
          </ul>
        )}
        {code && code === 'ECONNABORTED' &&  msg.includes('timeout') && (
          <ul>
            <li>
              <strong>Oops...</strong>
            </li>
            <li>
              Połączenie internetowe jest zbyt wolne. Spróbuj ponownie później.
            </li>
          </ul>
        )}
      </AlertTitle>
      {/* { JSON.stringify(props.error)} */}
      {response && (
        <ul>
          <li>Status: {response.status}</li>
          <li>Title: {response.data.title}</li>
          <li>Comment: {response.data.errors?.Comment[0]}</li>
        </ul>
      )}
    </Alert>
  );
}

export default AlertDialog;