
import './App.scss';
import { createBrowserRouter, RouterProvider, Outlet, useParams} from "react-router-dom";

import Home from './pages/home/Home';
import QR from './pages/qr/QR';
import Photos from './pages/photos/Photos';
//import Upload from './pages/upload/Upload';
import Upload from './pages/upload/UploadSteps';
import Header from './components/Header/Header';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { AuthProvider } from "./components/AuthContext/AuthContext";
import Login from './components/Login/Login';
import Page404 from './pages/page404/Page404';

function App() {
  const {id} = useParams();
  console.log("id", id);
  const Layout = (props) => {
    return (
      <div className="App">
        {/* <Header
          sections={router.routes[0].children}
        /> */}
        <main className="content">
          <Outlet id={id}/>
        </main>
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/:id',
      element: <Layout />,
      children: [
        {
          path: 'login',
          element: <Login />,
          title: 'Logowanie',
        },
        {
          path: 'main',
          element: <ProtectedRoute component={Home} />,
          title: 'Strona Główna',
        },
        {
          path: 'photos',
          element: <ProtectedRoute component={Photos} />,
          title: 'Zdjęcia',
        },
        {
          path: 'upload',
          element: <ProtectedRoute component={Upload} />,
          title: 'Wrzuć zdjęcie',
        },
        {
          path: 'qr',
          element: <ProtectedRoute component={QR} />,
          title: 'Kod QR',
        },
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  const isDecorative = (_path) => {
    return router.state.location.pathname.includes(_path);
  }

  return (
    <div
      className={`app ${isDecorative('main') ? 'decorative' : ''} ${isDecorative('photos') ? 'photos' : ''}`}
    >
      <AuthProvider>
        <RouterProvider router={router} />
        {/* <Routes children={router.routes}>{router.routes}</Routes> */}
      </AuthProvider>
      {/* <AuthProvider>
        <Routes>
          <Route path="/login" component={Login} />
          <ProtectedRoute exact path="/:id" component={Home} />
        </Routes>
      </AuthProvider> */}
    </div>
  );
}

export default App;
