import { Container } from '@mui/material';
import React from 'react';

const FixedTopCouple = (props) => {
  return (
    <div className={`couple-wrapper ${props.size}`}>
      <Container maxWidth="sm" className="container-wrapper">
        {/* <img
          className="bird bird-left"
          alt=""
          src="../src/assets/images/bird-right.png"
        /> */}
        <div className="bird bird-left"></div>
        <div className="image-wrapper">
          <img src={props.image} alt="" />
        </div>
        <div className="bird bird-right"></div>
        {/* <img
          className="bird bird-left"
          alt=""
          src="../../../src/assets/images/bird-right.png"
        /> */}
      </Container>
    </div>
  );
};

export default FixedTopCouple;
